import { makeStyles, Paper, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import { useTranslation } from "i18n";
import { useRouter } from "next/router";
import ChildrenFriendlyButton from "./ChildrenFriendlyButton";

const useStyles = makeStyles((theme) => ({
  item: {
    padding: theme.spacing(2),
    height: 220,
    width: 150,
    [theme.breakpoints.down("xs")]: {
      height: 200,
      width: 150,
    },
    "&:hover": {
      scale: 1.04,
      borderRadius: theme.spacing(2),
    },
    transition: theme.transitions.create(["scale", "border-radius"], {
      duration: theme.transitions.duration.standard,
    }),
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    color: "white",
    cursor: "pointer",
    borderRadius: theme.shape.borderRadius,
  },
  itemSmall: {
    height: 105,
    width: 80,
    padding: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      height: 90,
      width: 70,
    },
  },
  titleSmall: {
    ...theme.typography.caption,
    lineHeight: 1,
  },
}));

const ItemCard = ({ itemId, small, title, className, subtitle, assetUrl }) => {
  const classes = useStyles();
  const router = useRouter();

  const navigateToItem = () => {
    if (itemId) router.push(`/item?id=${itemId}`);
  };
  
  return (
    <ChildrenFriendlyButton fullWidth={false} onClick={navigateToItem}>
      <Paper
        elevation={8}
        className={clsx(
          classes.item,
          small && classes.itemSmall,
          className,
        )}
        style={{
          backgroundImage: `linear-gradient(rgba(245, 246, 252, 0), rgba(4, 4, 4, 0.75)), url(${assetUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Typography
          variant="body1"
          className={clsx("bold", small && classes.titleSmall)}
        >
          {title}
        </Typography>
        <Typography variant="caption">
          {subtitle}
        </Typography>
      </Paper>
    </ChildrenFriendlyButton>
  );
};

ItemCard.Skeleton = ({ className }) => {
  const classes = useStyles();
  return (
    <Paper className={clsx(classes.item, className)}>
      <Skeleton width="100%" height="100%" />
    </Paper>
  );
};

ItemCard.NotFound = ({ className }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Paper className={clsx(classes.item, className)}>
      <Skeleton width="100%" height="40%" />
      <Typography variant="body1" color="textSecondary" className="boldy">
        {t("RESULTS_NOT_FOUND")}
      </Typography>
    </Paper>
  );
};

export default ItemCard;
