import {
  Avatar,
  Badge,
  Box, makeStyles, Typography,
} from "@material-ui/core";
import React, { useMemo } from "react";
import { isRoleTicked } from "utils";
import TickDeckagram from "components/Icons/TickDeckagram";
import clsx from "clsx";
import LevelBadge from "components/LevelBadge";

const useStyles = makeStyles((theme) => ({
  tick: {
    width: 20,
    fill: "#1CCAFF",
    marginLeft: theme.spacing(1),
    fontSize: "inherit",
  },
  userbox: {
    display: "flex",
    alignItems: "center",
  },
  usernameInnerbox: {
    marginLeft: theme.spacing(1),
    textAlign: "left",
    lineHeight: 1,
  },
  username: {
    display: "flex",
  },
}));

const ResourceProfile = ({
  avatarUrl,
  username,
  level,
  children,
  roleId,
  tickSize,
  usernameProps,
  usernameContainerClassName,
  avatarProps,
  className,
}) => {
  const classes = useStyles();
  const tick = useMemo(() => isRoleTicked(roleId), [roleId]);

  return (
    <Box className={clsx(classes.userbox, className)}>
      {avatarUrl && (
        <Badge
          overlap="circle"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          badgeContent={(
            level && <LevelBadge level={level} />
          )}
        >
          <Avatar src={avatarUrl} {...avatarProps} />
        </Badge>
      ) }
      <Box className={clsx(classes.usernameInnerbox, usernameContainerClassName)}>
        <Typography
          className={clsx("bold", classes.username)}
          variant="body2"
          color="textSecondary"
          {...usernameProps}
        >
          {username}
          { tick && (
            <TickDeckagram
              className={classes.tick}
              style={{ width: tickSize }}
            />
          )}
        </Typography>
        {children}
      </Box>
    </Box>
  );
};

export default ResourceProfile;
