import { Box, Tooltip, Typography } from "@material-ui/core";
import { LevelIcon } from "./Icons";

const LevelBadge = ({ level }) => (
  <Tooltip title={`Nivel ${level}`}>
    <Box
      justifyContent="center"
      alignItems="center"
      display="flex"
      position="relative"
    >
      <Box position="absolute" zIndex={-1} width={25} height={25}>
        <LevelIcon />
      </Box>
      <Typography style={{ color: "white" }} className="bold">
        {level}
      </Typography>
    </Box>
  </Tooltip>
);
export default LevelBadge;
