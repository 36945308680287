import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  childrenFriendly: {
    justifyContent: "left",
    textAlign: "left",
    padding: 0,
    height: "max-content",
    minWidth: "unset",
    "&:hover": {
      opacity: 0.9,
    },
  },
});
const ChildrenFriendlyButton = ({ children, ...rest }) => (
  <Button
    fullWidth
    className={useStyles().childrenFriendly}
    {...rest}
  >
    {children}
  </Button>
);

export default ChildrenFriendlyButton;
